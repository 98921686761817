<template>
  <v-app>
    <v-app-bar app color="primary" dark v-if="status">
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="d-flex d-md-none"
      ></v-app-bar-nav-icon>
      <template v-for="(navItem, key) in nav">
        <v-btn
          class="mr-2"
          text
          :to="{
            name: navItem.component,
          }"
          :key="key"
          exact
        >
          <v-icon class="mr-md-2">mdi-{{ navItem.icon }}</v-icon>
          <span class="d-none d-md-inline-block">{{ navItem.title }}</span>
        </v-btn>
      </template>
      <v-spacer></v-spacer>
      <span class="d-none d-sm-inline-block text-truncate" style="max-width: 4em">
        {{ user.name }}
      </span>
      <v-btn class="mx-2 d-none d-sm-flex" fab small outlined @click="signOut">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app temporary color="primary" dark v-if="status">
      <v-list-item>
        <v-list-item-content>
          <v-img src="@/assets/logo_orange.svg"></v-img>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle class="text-center"> Приложение для кафе </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            {{ user.name }}
          </v-list-item-title>
          <v-list-item-subtitle></v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn class="mx-2" fab small outlined @click="signOut">
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>

      <v-list nav dense>
        <v-list-item-group v-model="group">
          <v-list-item
            v-for="(navItem, key) in nav"
            :key="key"
            :to="{
              name: navItem.component,
            }"
            exact
          >
            <v-list-item-icon>
              <v-icon>mdi-{{ navItem.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ navItem.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-divider></v-divider>
    </v-navigation-drawer>

    <v-main>
      <login v-if="!status"></login>
      <router-view v-if="status" />
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Login from "./components/Login.vue";

export default {
  components: { Login },
  name: "App",

  data: () => ({
    drawer: false,
    group: null,
    nav: [
      {
        title: "Заказы",
        component: "Home",
        icon: "order-bool-ascending-variant",
      },
      {
        title: "Разделы",
        component: "Sections",
        icon: "folder-outline",
      },
      {
        title: "Блюда",
        component: "Meals",
        icon: "food",
      },
      {
        title: "Добавки для вкуса",
        component: "Additions",
        icon: "bottle-soda-classic-outline",
      },
    ],
  }),
  computed: {
    ...mapState("login", ["status", "user"]),
  },
  methods: {
    ...mapActions("login", ["signOut"]),
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>
