function getCookie(name) {
  let result;
  document.cookie.split(";").forEach((el) => {
    const [key, value] = el.split("=");
    if (key.trim() === name) {
      result = value;
    }
  });
  return result;
}

export default {
  namespaced: true,
  state: () => ({
    status: localStorage.getItem("user") && getCookie("mrbag") !== undefined,
    user: {
      name: localStorage.getItem("user"),
    },
  }),
  getters: {

  },
  mutations: {
    loginSuccessfull(state, user) {
      state.status = true;
      state.user = { ...user };
      localStorage.setItem("user", user.name);
    },
    loginFailed(state) {
      state.status = false;
      state.user = {};
      localStorage.removeItem("user");
    },
  },
  actions: {
    async signUp({ commit, rootState }, user) {
      const arData = {
        username: user.username,
        password: user.password,
      };
      const response = await fetch(`${rootState.apiUrl.replace("api/", "")}login`, {
        method: "POST",
        body: JSON.stringify(arData),
      });

      if (response.ok) {
        commit("loginSuccessfull", {
          name: user.username,
        });
      } else {
        commit("loginFailed");

        let errMessage = "";
        if (response.status >= 400 && response.status < 500) {
          errMessage = "Неверный логин или пароль.";
        } else {
          errMessage = "Попробуйте позже или обратитесь к администратору.";
        }

        throw new Error(errMessage);
      }
    },
    async signOut({ commit, rootState }) {
      const response = await fetch(`${rootState.apiUrl.replace("api/", "")}logout`, {
        method: "POST",
      });
      if (response.ok) {
        commit("loginFailed");
      }
    },
  },

};
