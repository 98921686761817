<template>
  <v-form v-model="valid" @submit.prevent="goSignUp">
    <v-card elevation="6" class="mx-auto pa-6" max-width="500px">
      <v-container>
        <v-row>
          <v-col>
            <h1>Выполните вход в систему</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="username"
              :rules="nameRules"
              prepend-icon="mdi-account"
              :counter="10"
              label="Имя пользователя"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="password"
              :rules="passwordRules"
              prepend-icon="mdi-lock"
              :append-icon="showPassw ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
              :type="showPassw ? 'text' : 'password'"
              @click:append="showPassw = !showPassw"
              label="Пароль"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-alert
              v-model="alert.isActive"
              border="left"
              close-text="Close Alert"
              dark
              dismissible
              :type="alert.type"
            >
              {{ alert.text }}
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn :disabled="!valid" :loading="loading" color="success" class="mr-4" type="submit">
              Войти
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Login",
  data: () => ({
    valid: true,
    loading: false,
    showPassw: false,
    username: "",
    password: "",
    nameRules: [
      (v) => !!v || "Введите имя пользователя",
      (v) => v.length <= 10 || "Name must be less than 10 characters",
    ],
    passwordRules: [
      (v) => !!v || "Введите пароль",
      // (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    alert: {
      isActive: false,
      text: "",
      type: "error",
    },
  }),
  methods: {
    ...mapActions("login", ["signUp"]),
    async goSignUp() {
      this.loading = true;

      await this.signUp({
        username: this.username,
        password: this.password,
      })
        .then(() => {
          this.alert.isActive = false;
          this.alert.text = "";
          this.loading = false;
        })
        .catch((err) => {
          if (err.name === "Error") {
            this.alert.text = err.message;
          } else {
            this.alert.text = "Ошибка: Проверьте сетевое соединение и попробуйте ещё раз.";
          }
          this.alert.isActive = true;
          this.loading = false;
        });
    },
  },
};
</script>
