import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/meals",
    name: "Meals",
    component: () => import("../views/Meals.vue"),
  },
  {
    path: "/additions",
    name: "Additions",
    component: () => import("../views/Additions.vue"),
  },
  {
    path: "/sections",
    name: "Sections",
    component: () => import("../views/Sections.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
