import Vue from "vue";
import Vuex from "vuex";
import login from "./login";

Vue.use(Vuex);

const tempUrl = "/api/";

const prefix = ["large", "small", "menu", "order"];

async function uploadImageToServer(file) {
  const result = {
    responseOk: false,
    responseStatus: undefined,
    imgFileName: undefined,
    error: undefined,
  };

  if (file) {
    const dataFile = new FormData();
    dataFile.append("image", file);

    const response = await fetch(`${tempUrl}meals/image`, {
      method: "POST",
      body: dataFile,
    });

    result.responseOk = response.ok;
    result.responseStatus = response.status;

    if (response.ok) {
      const dataID = await response.json();
      result.imgFileName = dataID.imageFileName;
    } else {
      result.error = response.statusText;
    }
  }

  return result;
}

async function deleteImage(imageName) {
  const result = {
    responseOk: false,
    responseStatus: undefined,
    imgFileName: undefined,
    error: undefined,
  };

  if (imageName) {
    const response = await fetch(`${tempUrl}meals/image/${imageName}`, {
      method: "DELETE",
    });

    result.responseOk = response.ok;
    result.responseStatus = response.status;

    await response.json();

    if (!response.ok) {
      result.error = response.statusText;
    }
  }

  return result;
}

export default new Vuex.Store({
  state: {
    apiUrl: tempUrl,
    arSections: {},
    arAdditions: {},
    arMeals: {},
    arOrders: {},
    sectionUpdateTime: 0,
    additionUpdateTime: 0,
    mealUpdateTime: 0,
    updateTimeMaxInterval: 5 * 60, // 5 минут
    alertText: {
      errLoadData: "Не удалось загрузить данные. Проверьте соединение и попробуйте позже.",
      errAddData: "Не удалось добавить/изменить запись. Проверьте соединение и попробуйте позже.",
      errChangeData: "Не удалось добавить/изменить запись. Проверьте соединение и попробуйте позже.",
      errDeleteData: "Не удалось удалить запись. Проверьте соединение и попробуйте позже.",
    },
  },
  getters: {
    getActiveSectionList(state) {
      const arResult = [];
      Object.keys(state.arSections).forEach((item) => {
        if (state.arSections[item].isActive) {
          const params = { ...state.arSections[item] };
          params.id = +item;
          arResult.push(params);
        }
      });

      return arResult;
    },
    getActiveAdditionList(state) {
      const arResult = [];
      Object.keys(state.arAdditions).forEach((item) => {
        if (state.arAdditions[item].isActive) {
          const params = { ...state.arAdditions[item] };
          params.id = +item;
          arResult.push(params);
        }
      });

      return arResult;
    },
    getActiveMealList(state) {
      const arResult = [];
      Object.keys(state.arMeals).forEach((item) => {
        if (state.arMeals[item].isActive) {
          const params = { ...state.arMeals[item] };
          params.id = +item;
          arResult.push(params);
        }
      });

      return arResult;
    },
  },
  mutations: {
    addSectionOne(state, data) {
      Vue.set(state.arSections, data.id, {
        name: data.data.name,
        url: data.data.url,
        isActive: data.data.isActive,
      });
    },
    editSection(state, data) {
      state.arSections[data.id] = {
        name: data.data.name,
        url: data.data.url,
        isActive: data.data.isActive,
      };
    },
    deleteSection(state, data) {
      Vue.delete(state.arSections, data.id);
    },
    addAddition(state, data) {
      Vue.set(state.arAdditions, data.id, {
        name: data.data.name,
        price: data.data.price,
        isActive: data.data.isActive,
      });
    },
    editAddition(state, data) {
      state.arAdditions[data.id] = {
        name: data.data.name,
        price: data.data.price,
        isActive: data.data.isActive,
      };
    },
    deleteAddition(state, data) {
      Vue.delete(state.arAdditions, data.id);
    },
    addMeal(state, data) {
      let arAdditions = [];
      if (data.data.additions) {
        arAdditions = [...data.data.additions];
      }
      Vue.set(state.arMeals, data.id, {
        isActive: data.data.isActive,
        sectionId: +data.data.sectionId,
        name: data.data.name,
        description: data.data.description,
        price: +data.data.price,
        weight: data.data.weight,
        arAdditions,
        largeImgFileName: data.data.largeImgFileName,
        smallImgFileName: data.data.smallImgFileName,
        menuImgFileName: data.data.menuImgFileName,
        orderImgFileName: data.data.orderImgFileName,
      });
    },
    editMeal(state, data) {
      state.arMeals[data.id] = {
        isActive: data.data.isActive,
        sectionId: data.data.sectionId,
        name: data.data.name,
        description: data.data.description,
        price: data.data.price,
        weight: data.data.weight,
        arAdditions: data.data.additions,
        largeImgFileName: data.data.largeImgFileName,
        smallImgFileName: data.data.smallImgFileName,
        menuImgFileName: data.data.menuImgFileName,
        orderImgFileName: data.data.orderImgFileName,
      };
    },
    deleteMeal(state, data) {
      Vue.delete(state.arMeals, data.id);
    },
    addOrder(state, data) {
      Vue.set(state.arOrders, data.id, {
        status: data.data.status,
        positions: data.data.positions,
        deliveryType: data.data.deliveryType,
        deliveryTime: data.data.deliveryTime,
        clientName: data.data.clientName,
        clientPhone: data.data.clientPhone,
        address: data.data.address,
        comment: data.data.comment,
        price: data.data.price,
        createDate: data.data.createDate ? data.data.createDate : data.data.createdAt * 1000,
      });
    },
    editOrder(state, data) {
      state.arOrders[data.id] = {
        status: data.data.status,
        positions: data.data.positions,
        deliveryType: data.data.deliveryType,
        deliveryTime: data.data.deliveryTime,
        clientName: data.data.clientName,
        clientPhone: data.data.clientPhone,
        address: data.data.address,
        comment: data.data.comment,
        price: data.data.price,
        createDate: data.data.createDate,
      };
    },
    deleteOrder(state, data) {
      Vue.delete(state.arOrders, data.id);
    },
    changeUpdateTime(state, dataName) {
      const dataNameStr = `${dataName}UpdateTime`;

      if (dataNameStr in state) {
        state[dataNameStr] = (new Date()).getTime();
      }
    },
  },
  actions: {
    async addSection({ commit }, data) {
      const arData = {
        name: data.name,
        url: data.url,
        isActive: data.isActive,
      };

      const response = await fetch(`${tempUrl}sections`, {
        method: "POST",
        body: JSON.stringify(arData),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const dataID = await response.json();

        commit("addSectionOne", {
          id: dataID.id,
          data: arData,
        });
      } else if (response.status === 401) {
        commit("login/loginFailed");
      }
    },
    async editSection({ commit }, data) {
      const arData = {
        name: data.name,
        url: data.url,
        isActive: data.isActive,
      };
      const response = await fetch(`${tempUrl}sections/${data.id}`, {
        method: "PUT",
        body: JSON.stringify(arData),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        await response.json();

        commit("editSection", {
          id: data.id,
          data: arData,
        });
      } else if (response.status === 401) {
        commit("login/loginFailed");
      }
    },
    async deleteSection({ commit }, data) {
      const response = await fetch(`${tempUrl}sections/${data.id}`, {
        method: "DELETE",
      });

      if (response.ok) {
        await response.json();

        commit("deleteSection", {
          id: data.id,
        });
      } else if (response.status === 401) {
        commit("login/loginFailed");
      }
    },
    async getSectionList({ commit }) {
      const response = await fetch(`${tempUrl}sections`);

      if (response.ok) {
        const data = await response.json();

        if (data) {
          commit("changeUpdateTime", "section");

          data.map((value) => {
            commit("addSectionOne", {
              id: value.id,
              data: value,
            });
            return true;
          });
        }
      } else if (response.status === 401) {
        commit("login/loginFailed");
      }
    },
    async addAddition({ commit }, data) {
      const arData = {
        name: data.name,
        price: +data.price,
        isActive: data.isActive,
      };

      const response = await fetch(`${tempUrl}additions`, {
        method: "POST",
        body: JSON.stringify(arData),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const dataID = await response.json();

        commit("addAddition", {
          id: dataID.id,
          data: arData,
        });
      } else if (response.status === 401) {
        commit("login/loginFailed");
      }
    },
    async editAddition({ commit }, data) {
      const url = `${tempUrl}additions/${data.id}`;
      const arData = {
        name: data.name,
        price: +data.price,
        isActive: data.isActive,
      };
      const response = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(arData),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        await response.json();

        commit("editAddition", {
          id: data.id,
          data: arData,
        });
      } else if (response.status === 401) {
        commit("login/loginFailed");
      }
    },
    async deleteAddition({ commit }, data) {
      const response = await fetch(`${tempUrl}additions/${data.id}`, {
        method: "DELETE",
      });

      if (response.ok) {
        await response.json();

        commit("deleteAddition", {
          id: data.id,
        });
      } else if (response.status === 401) {
        commit("login/loginFailed");
      }
    },
    async getAdditionList({ commit }) {
      const response = await fetch(`${tempUrl}additions`);

      if (response.ok) {
        const data = await response.json();

        if (data) {
          commit("changeUpdateTime", "addition");

          data.map((value) => {
            commit("addAddition", {
              id: value.id,
              data: value,
            });
            return true;
          });
        }
      } else if (response.status === 401) {
        commit("login/loginFailed");
      }
    },
    async addMeal({ commit }, data) {
      const arData = {
        isActive: data.isActive,
        sectionId: data.sectionId,
        name: data.name,
        description: data.description,
        price: +data.price,
        weight: data.weight,
        additions: data.arAdditions,
        largeImgFileName: undefined,
        smallImgFileName: undefined,
        menuImgFileName: undefined,
        orderImgFileName: undefined,
      };

      let imgResponseData;
      if (data[`${prefix[0]}ImgFileValue`]) {
        imgResponseData = await uploadImageToServer(data[`${prefix[0]}ImgFileValue`]);

        if (imgResponseData.responseOk && imgResponseData.imgFileName) {
          arData[`${prefix[0]}ImgFileName`] = imgResponseData.imgFileName;
        } else if (imgResponseData.responseStatus === 401) {
          commit("login/loginFailed");
        }
      }
      if (data[`${prefix[1]}ImgFileValue`]) {
        imgResponseData = await uploadImageToServer(data[`${prefix[1]}ImgFileValue`]);

        if (imgResponseData.responseOk && imgResponseData.imgFileName) {
          arData[`${prefix[1]}ImgFileName`] = imgResponseData.imgFileName;
        } else if (imgResponseData.responseStatus === 401) {
          commit("login/loginFailed");
        }
      }
      if (data[`${prefix[2]}ImgFileValue`]) {
        imgResponseData = await uploadImageToServer(data[`${prefix[2]}ImgFileValue`]);

        if (imgResponseData.responseOk && imgResponseData.imgFileName) {
          arData[`${prefix[2]}ImgFileName`] = imgResponseData.imgFileName;
        } else if (imgResponseData.responseStatus === 401) {
          commit("login/loginFailed");
        }
      }
      if (data[`${prefix[3]}ImgFileValue`]) {
        imgResponseData = await uploadImageToServer(data[`${prefix[3]}ImgFileValue`]);

        if (imgResponseData.responseOk && imgResponseData.imgFileName) {
          arData[`${prefix[3]}ImgFileName`] = imgResponseData.imgFileName;
        } else if (imgResponseData.responseStatus === 401) {
          commit("login/loginFailed");
        }
      }

      if (arData.largeImgFileName && arData.smallImgFileName &&
        arData.menuImgFileName && arData.orderImgFileName) {
        const response = await fetch(`${tempUrl}meals`, {
          method: "POST",
          body: JSON.stringify(arData),
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const dataID = await response.json();

          commit("addMeal", {
            id: dataID.id,
            data: arData,
          });
        } else if (response.status === 401) {
          commit("login/loginFailed");
        }
      }
    },
    async editMeal({ state, commit }, data) {
      const arData = {
        isActive: data.isActive,
        sectionId: data.sectionId,
        name: data.name,
        description: data.description,
        price: +data.price,
        weight: data.weight,
        additions: data.arAdditions,
        largeImgFileName: data.largeImgFileName,
        smallImgFileName: data.smallImgFileName,
        menuImgFileName: data.menuImgFileName,
        orderImgFileName: data.orderImgFileName,
      };

      let imgResponseData;
      if (data[`${prefix[0]}ImgFileValue`]) {
        if (state.arMeals[data.id][`${prefix[0]}ImgFileName`]) {
          await deleteImage(state.arMeals[data.id][`${prefix[0]}ImgFileName`]);
        }

        imgResponseData = await uploadImageToServer(data[`${prefix[0]}ImgFileValue`]);

        if (imgResponseData.responseOk && imgResponseData.imgFileName) {
          arData[`${prefix[0]}ImgFileName`] = imgResponseData.imgFileName;
        } else if (imgResponseData.responseStatus === 401) {
          commit("login/loginFailed");
        }
      }
      if (data[`${prefix[1]}ImgFileValue`]) {
        if (state.arMeals[data.id][`${prefix[1]}ImgFileName`]) {
          await deleteImage(state.arMeals[data.id][`${prefix[1]}ImgFileName`]);
        }

        imgResponseData = await uploadImageToServer(data[`${prefix[1]}ImgFileValue`]);

        if (imgResponseData.responseOk && imgResponseData.imgFileName) {
          arData[`${prefix[1]}ImgFileName`] = imgResponseData.imgFileName;
        } else if (imgResponseData.responseStatus === 401) {
          commit("login/loginFailed");
        }
      }
      if (data[`${prefix[2]}ImgFileValue`]) {
        if (state.arMeals[data.id][`${prefix[2]}ImgFileName`]) {
          await deleteImage(state.arMeals[data.id][`${prefix[2]}ImgFileName`]);
        }

        imgResponseData = await uploadImageToServer(data[`${prefix[2]}ImgFileValue`]);

        if (imgResponseData.responseOk && imgResponseData.imgFileName) {
          arData[`${prefix[2]}ImgFileName`] = imgResponseData.imgFileName;
        } else if (imgResponseData.responseStatus === 401) {
          commit("login/loginFailed");
        }
      }
      if (data[`${prefix[3]}ImgFileValue`]) {
        if (state.arMeals[data.id][`${prefix[3]}ImgFileName`]) {
          await deleteImage(state.arMeals[data.id][`${prefix[3]}ImgFileName`]);
        }

        imgResponseData = await uploadImageToServer(data[`${prefix[3]}ImgFileValue`]);

        if (imgResponseData.responseOk && imgResponseData.imgFileName) {
          arData[`${prefix[3]}ImgFileName`] = imgResponseData.imgFileName;
        } else if (imgResponseData.responseStatus === 401) {
          commit("login/loginFailed");
        }
      }

      if (arData.largeImgFileName && arData.smallImgFileName &&
        arData.menuImgFileName && arData.orderImgFileName) {
        const response = await fetch(`${tempUrl}meals/${data.id}`, {
          method: "PUT",
          body: JSON.stringify(arData),
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          await response.json();

          commit("editMeal", {
            id: data.id,
            data: arData,
          });
        } else if (response.status === 401) {
          commit("login/loginFailed");
        }
      }
    },
    async deleteMeal({ state, commit }, data) {
      if (state.arMeals[data.id][`${prefix[0]}ImgFileName`]) {
        await deleteImage(state.arMeals[data.id][`${prefix[0]}ImgFileName`]);
      }
      if (state.arMeals[data.id][`${prefix[1]}ImgFileName`]) {
        await deleteImage(state.arMeals[data.id][`${prefix[1]}ImgFileName`]);
      }
      if (state.arMeals[data.id][`${prefix[2]}ImgFileName`]) {
        await deleteImage(state.arMeals[data.id][`${prefix[2]}ImgFileName`]);
      }
      if (state.arMeals[data.id][`${prefix[3]}ImgFileName`]) {
        await deleteImage(state.arMeals[data.id][`${prefix[3]}ImgFileName`]);
      }

      const response = await fetch(`${tempUrl}meals/${data.id}`, {
        method: "DELETE",
      });

      if (response.ok) {
        await response.json();

        commit("deleteMeal", {
          id: data.id,
        });
      } else if (response.status === 401) {
        commit("login/loginFailed");
      }
    },
    async getMealList({ commit }) {
      const response = await fetch(`${tempUrl}meals`);

      if (response.ok) {
        const data = await response.json();

        if (data) {
          commit("changeUpdateTime", "meal");

          data.map((value) => {
            commit("addMeal", {
              id: value.id,
              data: value,
            });
            return true;
          });
        }
      } else if (response.status === 401) {
        commit("login/loginFailed");
      }
    },
    async addOrder({ commit }, data) {
      const arData = {
        status: data.status,
        positions: data.positions,
        deliveryType: data.deliveryType,
        deliveryTime: data.deliveryTime,
        clientName: data.clientName,
        clientPhone: data.clientPhone,
        address: data.address,
        comment: data.comment,
        price: data.price,
        createDate: new Date().getTime(), // temp
      };
      const response = await fetch(`${tempUrl}orders`, {
        method: "POST",
        body: JSON.stringify(arData),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const dataID = await response.json();

        commit("addOrder", {
          id: dataID.id,
          data: dataID,
        });
      } else if (response.status === 401) {
        commit("login/loginFailed");
      }
    },
    async editOrder({ commit }, data) {
      const arData = {
        status: data.status,
        positions: data.positions,
        deliveryType: data.deliveryType,
        deliveryTime: data.deliveryTime,
        clientName: data.clientName,
        clientPhone: data.clientPhone,
        address: data.address,
        comment: data.comment,
        price: data.price,
        createDate: data.createDate, // temp
      };
      const response = await fetch(`${tempUrl}orders/${data.id}`, {
        method: "PUT",
        body: JSON.stringify(arData),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        await response.json();

        commit("editOrder", {
          id: data.id,
          data: arData,
        });
      } else if (response.status === 401) {
        commit("login/loginFailed");
      }
    },
    async archiveOrder({ commit }, data) {
      const response = await fetch(`${tempUrl}orders/${data.id}/archive`, {
        method: "PATCH",
      });

      if (response.ok) {
        await response.json();
        commit("deleteOrder", {
          id: data.id,
        });
      } else if (response.status === 401) {
        commit("login/loginFailed");
      }
    },
    async getOrderList({ commit }) {
      const response = await fetch(`${tempUrl}orders`);

      if (response.ok) {
        const data = await response.json();

        if (data) {
          commit("changeUpdateTime", "order");

          data.map((value) => {
            commit("addOrder", {
              id: value.id,
              data: value,
            });
            return true;
          });
        }
      } else if (response.status === 401) {
        commit("login/loginFailed");
      }
    },
  },
  modules: {
    login,
  },
});
