<template>
  <div>
    <orders></orders>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {
    Orders: () => import("../components/Orders.vue"),
  },
  data: () => ({}),
};
</script>
